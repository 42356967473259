// import { Helmet } from "react-helmet";
import React from "react";

const PrivacyPage = () => {
  return (
    <div className="py-16 xl:py-36 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
      <div className="max-w-max lg:max-w-7xl mx-auto">
        <div className="relative z-10 mb-8 md:mb-2 md:px-6">
          <div className="text-base max-w-prose lg:max-w-none">
            <h2 className="leading-6 text-indigo-600 font-semibold tracking-wide uppercase">
              Website Terms and Conditions of Use
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Please read our Terms and Conditions of Use Policy before using
              our site.
            </p>
          </div>
        </div>
        <div className="relative">
          <svg
            className="hidden md:block absolute top-0 right-0 -mt-20 -mr-20"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="95e8f2de-6d30-4b7e-8159-f791729db21b"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#95e8f2de-6d30-4b7e-8159-f791729db21b)"
            />
          </svg>
          <svg
            className="hidden md:block absolute bottom-0 left-0 -mb-20 -ml-20"
            width={404}
            height={384}
            fill="none"
            viewBox="0 0 404 384"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="7a00fe67-0343-4a3c-8e81-c145097a3ce0"
                x={0}
                y={0}
                width={20}
                height={20}
                patternUnits="userSpaceOnUse"
              >
                <rect
                  x={0}
                  y={0}
                  width={4}
                  height={4}
                  className="text-gray-200"
                  fill="currentColor"
                />
              </pattern>
            </defs>
            <rect
              width={404}
              height={384}
              fill="url(#7a00fe67-0343-4a3c-8e81-c145097a3ce0)"
            />
          </svg>
          <div className="relative md:bg-white md:p-6">
            <div className="lg:grid lg:grid-cols-1 lg:gap-6">
              <div className="prose prose-indigo prose-lg text-gray-500 lg:max-w-none">
                <div className="text-2xl">1. Introduction</div>
                <p>
                  Welcome to SideHustleTool - owned and operated by DGF Digital
                  Inc. (“Company”, “we”, “our”, “us”)! As you have just clicked
                  our Terms of Service, please pause, grab a cup of coffee and
                  carefully read the following pages. It will take you
                  approximately 20 minutes.
                </p>
                <div className="pt-3">
                  <p>
                    These Terms of Service (“Terms”, “Terms of Service”) govern
                    your use of our web pages located at{" "}
                    <a href="https://sidehustletool.com">
                      https://sidehustletool.com
                    </a>{" "}
                    and our mobile application SideHustleTool (together or
                    individually “Service”) owned and operated by DGF Digital
                    Inc. .
                  </p>
                </div>

                <div className="pt-3">
                  <p>
                    Your agreement with us includes these Terms and our Privacy
                    Policy (“Agreements”). You acknowledge that you have read
                    and understood Agreements, and agree to be bound of them.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    If you do not agree with (or cannot comply with) Agreements,
                    then you may not use the Service, but please{" "}
                    <a href="../contact">contact us</a> so we can try to find a
                    solution. These Terms apply to all visitors, users and
                    others who wish to access or use Service.
                  </p>
                </div>
                <div className="pt-3">
                  <p>Thank you for being responsible.</p>
                </div>

                <div className="text-2xl pt-2">2. Communications</div>
                <div>
                  <p>
                    By creating an Account on our Service, you agree to
                    subscribe to newsletters, marketing or promotional materials
                    and other information we may send. However, you may opt out
                    of receiving any, or all, of these communications from us by
                    following the unsubscribe link or by{" "}
                    <a href="../contact">contacting us.</a>.
                  </p>
                </div>

                <div className="text-2xl pt-2">3. Purchases</div>
                <div>
                  <p>
                    If you wish to purchase any product or service made
                    available through Service (“Purchase”), you may be asked to
                    supply certain information relevant to your Purchase
                    including, without limitation, your credit card number, the
                    expiration date of your credit card, your billing address,
                    and your shipping information.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    You represent and warrant that: (i) you have the legal right
                    to use any credit card(s) or other payment method(s) in
                    connection with any Purchase; and that (ii) the information
                    you supply to us is true, correct and complete.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    We may employ the use of third party services for the
                    purpose of facilitating payment and the completion of
                    Purchases. By submitting your information, you grant us the
                    right to provide the information to these third parties
                    subject to our Privacy Policy.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    We reserve the right to refuse or cancel your order at any
                    time for reasons including but not limited to: product or
                    service availability, errors in the description or price of
                    the product or service, error in your order or other
                    reasons.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    We reserve the right to refuse or cancel your order if fraud
                    or an unauthorized or illegal transaction is suspected.
                  </p>
                </div>
              </div>
              <div className="mt-6 prose prose-indigo prose-lg text-gray-500 lg:mt-0">
                <div className="text-2xl ">
                  4. Contests, Sweepstakes and Promotions
                </div>
                <div>
                  <p>
                    Any contests, sweepstakes or other promotions (collectively,
                    “Promotions”) made available through Service may be governed
                    by rules that are separate from these Terms of Service. If
                    you participate in any Promotions, please review the
                    applicable rules as well as our Privacy Policy. If the rules
                    for a Promotion conflict with these Terms of Service,
                    Promotion rules will apply.
                  </p>
                </div>
                <div className="text-2xl pt-2">5. Subscriptions</div>
                <p>
                  Some parts of Service are billed on a subscription basis
                  (“Subscription(s)”). You will be billed in advance on a
                  recurring and periodic basis (“Billing Cycle”). Billing cycles
                  are set either on a monthly or annual basis, depending on the
                  type of subscription plan you select when purchasing a
                  Subscription.
                </p>
                <div className="pt-3">
                  <p>
                    At the end of each Billing Cycle, your Subscription will
                    automatically renew under the exact same conditions unless
                    you cancel it or DGF Digital Inc. cancels it. You may cancel
                    your Subscription renewal either through your online account
                    management page or by <a href="../contact">contacting </a>{" "}
                    our support team.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    A valid payment method, including credit card or PayPal, is
                    required to process the payment for your subscription. You
                    shall provide DGF Digital Inc. with accurate and complete
                    billing information including full name, address, state, zip
                    code, telephone number, and a valid payment method
                    information. By submitting such payment information, you
                    automatically authorize DGF Digital Inc. to charge all
                    Subscription fees incurred through your account to any such
                    payment instruments.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    Should automatic billing fail to occur for any reason, DGF
                    Digital Inc. will issue an electronic invoice indicating
                    that you must proceed manually, within a certain deadline
                    date, with the full payment corresponding to the billing
                    period as indicated on the invoice.
                  </p>
                </div>

                <div className="text-2xl pt-2">6. Free Trial</div>
                <p>
                  DGF Digital Inc. may, at its sole discretion, offer a
                  Subscription with a free trial for a limited period of time
                  (“Free Trial”).
                </p>
                <div className="pt-3">
                  <p>
                    You may be required to enter your billing information in
                    order to sign up for Free Trial.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    If you do enter your billing information when signing up for
                    Free Trial, you will not be charged by DGF Digital Inc.
                    until Free Trial has expired. On the last day of Free Trial
                    period, unless you cancelled your Subscription, you will be
                    automatically charged the applicable Subscription fees for
                    the type of Subscription you have selected.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    At any time and without notice, DGF Digital Inc. reserves
                    the right to (i) modify Terms of Service of Free Trial
                    offer, or (ii) cancel such Free Trial offer.
                  </p>
                </div>

                <div className="text-2xl pt-2">7. Fee Changes</div>
                <p>
                  DGF Digital Inc., in its sole discretion and at any time, may
                  modify Subscription fees for the Subscriptions. Any
                  Subscription fee change will become effective at the end of
                  the then-current Billing Cycle.
                </p>
                <div className="pt-3">
                  <p>
                    DGF Digital Inc. will provide you with a reasonable prior
                    notice of any change in Subscription fees to give you an
                    opportunity to terminate your Subscription before such
                    change becomes effective.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    Your continued use of Service after Subscription fee change
                    comes into effect constitutes your agreement to pay the
                    modified Subscription fee amount.
                  </p>
                </div>
                <div className="text-2xl pt-2">8. Refunds</div>
                <p>
                  We issue refunds for Contracts within fourteen (14) days of
                  the original purchase of the Contract.
                </p>
                <div className="text-2xl pt-2">9. Content</div>
                <p>
                  Content found on or through this Service are the property of
                  DGF Digital Inc. or used with permission. You may not
                  distribute, modify, transmit, reuse, download, repost, copy,
                  or use said Content, whether in whole or in part, for
                  commercial purposes or for personal gain, without express
                  advance written permission from us.
                </p>
                <div className="text-2xl pt-2">10. Prohibited Uses</div>
                <p>
                  You may use Service only for lawful purposes and in accordance
                  with Terms. You agree not to use Service:
                </p>
                <div className="pt-3 pl-6">
                  <ul>
                    <li>
                      (a) In any way that violates any applicable national or
                      international law or regulation.
                    </li>
                    <li>
                      (b) For the purpose of exploiting, harming, or attempting
                      to exploit or harm minors in any way by exposing them to
                      inappropriate content or otherwise.
                    </li>
                    <li>
                      (c) To transmit, or procure the sending of, any
                      advertising or promotional material, including any “junk
                      mail”, “chain letter,” “spam,” or any other similar
                      solicitation.
                    </li>
                    <li>
                      (d) To impersonate or attempt to impersonate Company, a
                      Company employee, another user, or any other person or
                      entity.
                    </li>
                    <li>
                      (e) In any way that infringes upon the rights of others,
                      or in any way is illegal, threatening, fraudulent, or
                      harmful, or in connection with any unlawful, illegal,
                      fraudulent, or harmful purpose or activity.
                    </li>
                    <li>
                      (f) To engage in any other conduct that restricts or
                      inhibits anyone’s use or enjoyment of Service, or which,
                      as determined by us, may harm or offend Company or users
                      of Service or expose them to liability.
                    </li>
                  </ul>
                </div>
                <div className="pt-3">
                  <p>Additionally, you agree not to:</p>
                </div>
                <div className="pt-3 pl-6">
                  <ul>
                    <li>
                      (a) Use Service in any manner that could disable,
                      overburden, damage, or impair Service or interfere with
                      any other party’s use of Service, including their ability
                      to engage in real time activities through Service.
                    </li>
                    <li>
                      (b) Use any robot, spider, or other automatic device,
                      process, or means to access Service for any purpose,
                      including monitoring or copying any of the material on
                      Service.
                    </li>
                    <li>
                      (c) Use any manual process to monitor or copy any of the
                      material on Service or for any other unauthorized purpose
                      without our prior written consent.
                    </li>
                    <li>
                      (d) Use any device, software, or routine that interferes
                      with the proper working of Service.
                    </li>
                    <li>
                      (e) Introduce any viruses, trojan horses, worms, logic
                      bombs, or other material which is malicious or
                      technologically harmful.
                    </li>
                    <li>
                      (f) Attempt to gain unauthorized access to, interfere
                      with, damage, or disrupt any parts of Service, the server
                      on which Service is stored, or any server, computer, or
                      database connected to Service.
                    </li>
                    <li>
                      (g) Attack Service via a denial-of-service attack or a
                      distributed denial-of-service attack.
                    </li>
                    <li>
                      (h) Take any action that may damage or falsify Company
                      rating.
                    </li>
                    <li>
                      (i) Otherwise attempt to interfere with the proper working
                      of Service.
                    </li>
                  </ul>
                </div>
                <div className="text-2xl pt-2">11. Analytics</div>
                <p>
                  We may use third-party Service Providers to monitor and
                  analyze the use of our Service.
                </p>

                <div className="pt-3">
                  <p>
                    Google Analytics Google Analytics is a web analytics service
                    offered by Google that tracks and reports website traffic.
                    Google uses the data collected to track and monitor the use
                    of our Service. This data is shared with other Google
                    services. Google may use the collected data to contextualise
                    and personalise the ads of its own advertising network.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    For more information on the privacy practices of Google,
                    please visit the Google Privacy Terms web page:
                    <br />
                    <a href="https://policies.google.com/privacy?hl=en">
                      https://policies.google.com/privacy?hl=en
                    </a>
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    We also encourage you to review the Google's policy for
                    safeguarding your data:
                    <br />
                    <a href="https://support.google.com/analytics/answer/6004245">
                      https://support.google.com/analytics/answer/6004245
                    </a>
                    .
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    Firebase Firebase is analytics service provided by Google
                    Inc.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    You may opt-out of certain Firebase features through your
                    mobile device settings, such as your device advertising
                    settings or by following the instructions provided by Google
                    in their Privacy Policy:
                    <br />
                    <a href="https://policies.google.com/privacy?hl=en">
                      https://policies.google.com/privacy?hl=en
                    </a>
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    For more information on what type of information Firebase
                    collects, please visit the Google Privacy Terms web page:
                    <br />
                    <a href="https://policies.google.com/privacy?hl=en">
                      https://policies.google.com/privacy?hl=en
                    </a>
                  </p>
                </div>

                <div className="text-2xl pt-2">12. No Use By Minors</div>
                <p>
                  Service is intended only for access and use by individuals at
                  least eighteen (18) years old. By accessing or using any of
                  Company, you warrant and represent that you are at least
                  eighteen (18) years of age and with the full authority, right,
                  and capacity to enter into this agreement and abide by all of
                  the terms and conditions of Terms. If you are not at least
                  eighteen (18) years old, you are prohibited from both the
                  access and usage of Service.
                </p>
                <div className="text-2xl pt-2">13. Accounts</div>
                <p>
                  When you create an account with us, you guarantee that you are
                  above the age of 18, and that the information you provide us
                  is accurate, complete, and current at all times. Inaccurate,
                  incomplete, or obsolete information may result in the
                  immediate termination of your account on Service.
                </p>
                <div className="pt-3">
                  <p>
                    You are responsible for maintaining the confidentiality of
                    your account and password, including but not limited to the
                    restriction of access to your computer and/or account. You
                    agree to accept responsibility for any and all activities or
                    actions that occur under your account and/or password,
                    whether your password is with our Service or a third-party
                    service. You must notify us immediately upon becoming aware
                    of any breach of security or unauthorized use of your
                    account.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    You may not use as a username the name of another person or
                    entity or that is not lawfully available for use, a name or
                    trademark that is subject to any rights of another person or
                    entity other than you, without appropriate authorization.
                    You may not use as a username any name that is offensive,
                    vulgar or obscene.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    We reserve the right to refuse service, terminate accounts,
                    remove or edit content, or cancel orders in our sole
                    discretion.
                  </p>
                </div>

                <div className="text-2xl pt-2">14. Intellectual Property</div>
                <p>
                  Service and its original content (excluding Content provided
                  by users), features and functionality are and will remain the
                  exclusive property of DGF Digital Inc. and its licensors.
                  Service is protected by copyright, trademark, and other laws
                  of the United States and foreign countries. Our trademarks and
                  trade dress may not be used in connection with any product or
                  service without the prior written consent of DGF Digital Inc..
                </p>
                <div className="text-2xl pt-2">15. Copyright Policy</div>
                <p>
                  We respect the intellectual property rights of others. It is
                  our policy to respond to any claim that Content posted on
                  Service infringes on the copyright or other intellectual
                  property rights (“Infringement”) of any person or entity.
                </p>
                <div className="pt-3">
                  <p>
                    If you are a copyright owner, or authorized on behalf of
                    one, and you believe that the copyrighted work has been
                    copied in a way that constitutes copyright infringement,
                    please submit your claim via email to [●], with the subject
                    line: “Copyright Infringement” and include in your claim a
                    detailed description of the alleged Infringement as detailed
                    below, under “DMCA Notice and Procedure for Copyright
                    Infringement Claims”
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    You may be held accountable for damages (including costs and
                    attorneys' fees) for misrepresentation or bad-faith claims
                    on the infringement of any Content found on and/or through
                    Service on your copyright.
                  </p>
                </div>

                <div className="text-2xl pt-2">
                  16. DMCA Notice and Procedure for Copyright Infringement
                  Claims
                </div>
                <p>
                  You may submit a notification pursuant to the Digital
                  Millennium Copyright Act (DMCA) by providing our Copyright
                  Agent with the following information in writing (see 17 U.S.C
                  512(c)(3) for further detail):
                </p>
                <div className="pt-3 pl-6">
                  <ul>
                    <li>
                      (a) an electronic or physical signature of the person
                      authorized to act on behalf of the owner of the
                      copyright's interest;
                    </li>
                    <li>
                      (b) a description of the copyrighted work that you claim
                      has been infringed, including the URL (i.e., web page
                      address) of the location where the copyrighted work exists
                      or a copy of the copyrighted work;
                    </li>
                    <li>
                      (c) identification of the URL or other specific location
                      on Service where the material that you claim is infringing
                      is located;
                    </li>
                    <li>
                      (d) your address, telephone number, and email address;
                    </li>
                    <li>
                      (e) a statement by you that you have a good faith belief
                      that the disputed use is not authorized by the copyright
                      owner, its agent, or the law;
                    </li>
                    <li>
                      (f) a statement by you, made under penalty of perjury,
                      that the above information in your notice is accurate and
                      that you are the copyright owner or authorized to act on
                      the copyright owner's behalf.
                    </li>
                  </ul>
                </div>
                <div className="pt-3">
                  <p>You can contact our Copyright Agent via email to us.</p>
                </div>

                <div className="text-2xl pt-2">
                  17. Error Reporting and Feedback
                </div>
                <p>
                  You may provide us directly at info@sidehustletool.com with
                  information and feedback concerning errors, suggestions for
                  improvements, ideas, problems, complaints, and other matters
                  related to our Service (“Feedback”). You acknowledge and agree
                  that: (i) you shall not retain, acquire or assert any
                  intellectual property right or other right, title or interest
                  in or to the Feedback; (ii) Company may have development ideas
                  similar to the Feedback; (iii) Feedback does not contain
                  confidential information or proprietary information from you
                  or any third party; and (iv) Company is not under any
                  obligation of confidentiality with respect to the Feedback. In
                  the event the transfer of the ownership to the Feedback is not
                  possible due to applicable mandatory laws, you grant Company
                  and its affiliates an exclusive, transferable, irrevocable,
                  free-of-charge, sub-licensable, unlimited and perpetual right
                  to use (including copy, modify, create derivative works,
                  publish, distribute and commercialize) Feedback in any manner
                  and for any purpose.
                </p>
                <div className="text-2xl pt-2">
                  18. Links To Other Web Sites
                </div>
                <p>
                  Our Service may contain links to third party web sites or
                  services that are not owned or controlled by DGF Digital Inc..
                </p>
                <div className="pt-3">
                  <p>
                    DGF Digital Inc. has no control over, and assumes no
                    responsibility for the content, privacy policies, or
                    practices of any third party web sites or services. We do
                    not warrant the offerings of any of these
                    entities/individuals or their websites.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    YOU ACKNOWLEDGE AND AGREE THAT DGF DIGITAL INC. SHALL NOT BE
                    RESPONSIBLE OR LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY
                    DAMAGE OR LOSS CAUSED OR ALLEGED TO BE CAUSED BY OR IN
                    CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT,
                    GOODS OR SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD
                    PARTY WEB SITES OR SERVICES.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND
                    PRIVACY POLICIES OF ANY THIRD PARTY WEB SITES OR SERVICES
                    THAT YOU VISIT.
                  </p>
                </div>

                <div className="text-2xl pt-2">19. Disclaimer Of Warranty </div>
                <p>
                  THESE SERVICES ARE PROVIDED BY DGF DIGITAL INC. ON AN “AS IS”
                  AND “AS AVAILABLE” BASIS. DGF DIGITAL INC. MAKES NO
                  REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED,
                  AS TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION,
                  CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE
                  THAT YOUR USE OF THESE SERVICES, CONTENT, AND ANY SERVICES OR
                  ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.
                </p>
                <div className="pt-3">
                  <p>
                    NEITHER DGF DIGITAL INC. NOR ANY PERSON ASSOCIATED WITH DGF
                    DIGITAL INC. MAKES ANY WARRANTY OR REPRESENTATION WITH
                    RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY,
                    ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING
                    THE FOREGOING, NEITHER DGF DIGITAL INC. NOR ANYONE
                    ASSOCIATED WITH DGF DIGITAL INC. REPRESENTS OR WARRANTS THAT
                    THE SERVICES, CONTENT, OR ANY SERVICES OR ITEMS OBTAINED
                    THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE,
                    OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT THE
                    SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
                    VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR
                    ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL
                    OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    DGF DIGITAL INC. HEREBY DISCLAIMS ALL WARRANTIES OF ANY
                    KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE,
                    INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
                    MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR
                    PARTICULAR PURPOSE.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE
                    EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                  </p>
                </div>

                <div className="text-2xl pt-2">20. Limitation Of Liability</div>
                <p>
                  EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR
                  OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS HARMLESS FOR ANY
                  INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL
                  DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS' FEES AND ALL
                  RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR
                  AT TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR
                  ARBITRATION IS INSTITUTED), WHETHER IN AN ACTION OF CONTRACT,
                  NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN
                  CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION
                  ANY CLAIM FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM
                  THIS AGREEMENT AND ANY VIOLATION BY YOU OF ANY FEDERAL, STATE,
                  OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF DGF
                  DIGITAL INC. HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF
                  SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS
                  LIABILITY FOUND ON THE PART OF DGF DIGITAL INC., IT WILL BE
                  LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES,
                  AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR
                  PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR
                  LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES,
                  SO THE PRIOR LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
                </p>
                <div className="text-2xl pt-2">21. Termination</div>
                <p>
                  We may terminate or suspend your account and bar access to
                  Service immediately, without prior notice or liability, under
                  our sole discretion, for any reason whatsoever and without
                  limitation, including but not limited to a breach of Terms.
                </p>
                <div className="pt-3">
                  <p>
                    If you wish to terminate your account, you may simply
                    discontinue using Service.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    All provisions of Terms which by their nature should survive
                    termination shall survive termination, including, without
                    limitation, ownership provisions, warranty disclaimers,
                    indemnity and limitations of liability.
                  </p>
                </div>

                <div className="text-2xl pt-2">22. Governing Law</div>
                <p>
                  These Terms shall be governed and construed in accordance with
                  the laws of [●] without regard to its conflict of law
                  provisions.
                </p>
                <div className="pt-3">
                  <p>
                    Our failure to enforce any right or provision of these Terms
                    will not be considered a waiver of those rights. If any
                    provision of these Terms is held to be invalid or
                    unenforceable by a court, the remaining provisions of these
                    Terms will remain in effect. These Terms constitute the
                    entire agreement between us regarding our Service and
                    supersede and replace any prior agreements we might have had
                    between us regarding Service.
                  </p>
                </div>

                <div className="text-2xl pt-2">23. Changes To Service</div>
                <p>
                  We reserve the right to withdraw or amend our Service, and any
                  service or material we provide via Service, in our sole
                  discretion without notice. We will not be liable if for any
                  reason all or any part of Service is unavailable at any time
                  or for any period. From time to time, we may restrict access
                  to some parts of Service, or the entire Service, to users,
                  including registered users.
                </p>
                <div className="text-2xl pt-2">24. Amendments To Terms</div>
                <p>
                  We may amend Terms at any time by posting the amended terms on
                  this site. It is your responsibility to review these Terms
                  periodically.
                </p>
                <div className="pt-3">
                  <p>
                    Your continued use of the Platform following the posting of
                    revised Terms means that you accept and agree to the
                    changes. You are expected to check this page frequently so
                    you are aware of any changes, as they are binding on you.
                  </p>
                </div>
                <div className="pt-3">
                  <p>
                    By continuing to access or use our Service after any
                    revisions become effective, you agree to be bound by the
                    revised terms. If you do not agree to the new terms, you are
                    no longer authorized to use Service.
                  </p>
                </div>

                <div className="text-2xl pt-2">25. Waiver And Severability</div>
                <p>
                  No waiver by DGF DIGITAL INC. of any term or condition set
                  forth in Terms shall be deemed a further or continuing waiver
                  of such term or condition or a waiver of any other term or
                  condition, and any failure of DGF DIGITAL INC. to assert a
                  right or provision under Terms shall not constitute a waiver
                  of such right or provision.
                </p>
                <div className="pt-3">
                  <p>
                    If any provision of Terms is held by a court or other
                    tribunal of competent jurisdiction to be invalid, illegal or
                    unenforceable for any reason, such provision shall be
                    eliminated or limited to the minimum extent such that the
                    remaining provisions of Terms will continue in full force
                    and effect.
                  </p>
                </div>

                <div className="text-2xl pt-2">26. Acknowledgement</div>
                <p>
                  BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU
                  ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND
                  AGREE TO BE BOUND BY THEM.
                </p>
                <div className="text-2xl pt-2">27. Contact Us</div>
                <p>
                  Please send your feedback, comments, requests for technical
                  support: By visiting this page on our website:{" "}
                  <a href="../contact">https://sidehustletool.com/contact</a>.
                </p>
              </div>
            </div>
            <div className="mt-8 inline-flex rounded-md shadow">
              <a
                href="#"
                className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPage;
